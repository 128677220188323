<template><Logo/></template>

<script>
export default {
  name: 'TutoFr',
  components: {
    Logo: () => import('./inline.tuto_en.svg'),
  },
}
</script>
